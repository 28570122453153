<template>
  <div :class="$style.header">
    <h2 :class="$style.text">{{ text }}</h2>
    
    <!--<div :class="$style.stepsProgress">
      <div v-for="n in totalSteps" :key="n">
        <div :class="[$style.step, n === currentStep ? $style.current : '', n < currentStep ? $style.completed : '']">
          {{ n }}
        </div>
      </div>
    </div>-->
  </div>
</template>
<script>
export default {
  name: 'ReviewHeader',
  props: {
    text: String,
    currentStep: Number,
    totalSteps: Number
  }
}
</script>
<style module>
.header {
  margin: 0 auto 1rem;
  width: 100%;
}
.text {
  font-family: 'PT Serif';
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin: 0 0 1rem;
}
.icon {
  margin-right: 1.3125rem;
}
.stepsProgress {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 42%, rgba(0,0,0,1) 48%, rgba(255,255,255,1) 54%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.step {
  background: #ffffff;
  border: 3px solid #2B2B2B;
  border-radius: 50%;
  display: flex;
  align-items: center;
  font-family: 'PT Serif';
  justify-content: center;
  font-size: 1.5rem;
  width: 33px;
  height: 33px;
}
.step.current {
  background: #00C3A7;
}
.step.completed {
  background: #00C3A7;
}
@media screen and (min-width: 1200px) {
  .header {
    width: 75%;
  }
  .text {
    font-size: 3.5rem;
  }
}
</style>