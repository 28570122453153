<template>
  <div :class="$style.screen">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationScreen'
}
</script>
<style module>
.screen {
  padding: 0 0 2rem;
}
</style>