<template>
  <div class="review-autosuggest">
    <FormErrorMessages :messages="errorMessages" />
    <vue-autosuggest
      ref="autocomplete"
      :suggestions="suggestions"
      :inputProps="inputProps"
      :sectionConfigs="sectionConfigs"
      :renderSuggestion="renderSuggestion"
      :getSuggestionValue="getSuggestionValue"
    />
  </div>

</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import { isUndefined } from 'lodash'
import FormErrorMessages from '@/components/user-review/FormErrorMessages.vue'

export default {
  name: "ReviewAutoSuggest",
  components: {
    VueAutosuggest,
    FormErrorMessages
  },
  props: {
    errorMessages: [Array, Object]
  },
  data() {
    return {
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 50,
      inputProps: {
        id: "autosuggest__input",
        onInputChange: this.fetchResults,
        placeholder: "Search here...",
        class: "form-control",
        name: "hello"
      },
      suggestions: [],
      sectionConfigs: {
        reviews: {
          limit: 15,
          label: "Brands and Products",
          onSelected: selected => {
            this.selected = selected.item
            this.$emit('onAutoSuggestSelected', {
              reviewId: selected.item.id,
              reviewName: selected.item.title,
              reviewImage: selected.item.image_big
            })
            this.$emit('input', selected.item.title)
            //window.location.href = selected.item.url
          }
        },
      }
    };
  },
  computed: {
    sections () {
      return this.$store.state.autosuggest
    }
  },
  created () {
    // Set initial state
    if (!isUndefined(window.__initialState__) && !isUndefined(window.__initialState__.autosuggest)) {
        this.$store.commit('setAutosuggest', window.__initialState__.autosuggest)
    }
  },
  mounted () {
    document.getElementById('autosuggest__input').focus()
  },
  methods: {
    fetchResults(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {

        /*
        const photosPromise = axios.get(this.photosUrl);
        const usersPromise = axios.get(this.usersUrl);

        Promise.all([photosPromise, usersPromise]).then(values => {
          this.suggestions = [];
          this.selected = null;

          const photos = this.filterResults(values[0].data, val, "title");
          const users = this.filterResults(values[1].data, val, "name");

          users.length &&
            this.suggestions.push({ name: "destinations", data: users });
          photos.length &&
            this.suggestions.push({ name: "hotels", data: photos });
        });
        */
          this.suggestions = [];
          this.selected = null;

          const reviews = this.filterResults(this.sections.reviews.data, val, 'title');

          reviews.length &&
            this.suggestions.push({ name: "reviews", data: reviews });

      }, this.debounceMilliseconds);
    },
    filterResults(data, text, field) {
      return data
        .filter(item => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field];
          }
        })
        .sort();
    },
    renderSuggestion(suggestion) {
      if (suggestion.name === 'reviews') {
        return (
          <div>
            <img class={{ autosuggest__image: true, review: true }} src={suggestion.item.image} />
            {suggestion.item.title}
          </div>
        )
      } else {
        return suggestion.item.title;
      }
    },
    getSuggestionValue(suggestion) {
      let { name, item } = suggestion;
      return name == "hotels" ? item.title : item.title;
    }
  }
};
</script>

<style>
.review-autosuggest {
  margin: 0 0 1.5rem;
}
</style>
