<template>
	<div>
    <vue-autosuggest
      ref="autocomplete"
      :suggestions="suggestions"
      :inputProps="inputProps"
      :sectionConfigs="sectionConfigs"
      :renderSuggestion="renderSuggestion"
      :getSuggestionValue="getSuggestionValue"
		/>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import { isUndefined } from 'lodash'

export default {
  name: "SearchBarContainer",
  components: {
    VueAutosuggest
  },
  data() {
    return {
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 50,
      inputProps: {
        id: "autosuggest__input",
        onInputChange: this.fetchResults,
        placeholder: "",
        class: "form-control",
        name: "hello"
      },
      suggestions: [],
      sectionConfigs: {
        categories: {
          limit: 5,
          label: "Categories",
          onSelected: selected => {
            this.selected = selected.item
            window.location.href = selected.item.url
          }
        },
        reviews: {
          limit: 5,
          label: "Reviews",
          onSelected: selected => {
            this.selected = selected.item
            window.location.href = selected.item.url
          }
        },
        articles: {
          limit: 5,
          label: "Articles",
          onSelected: selected => {
            this.selected = selected.item
            window.location.href = selected.item.url
          }
        }
      }
    };
  },
  computed: {
    sections () {
      return this.$store.state.autosuggest
    }
  },
  created () {
    // Set initial state
    if (!isUndefined(window.__initialState__) && !isUndefined(window.__initialState__.autosuggest)) {
        this.$store.commit('setAutosuggest', window.__initialState__.autosuggest)
    }
  },
  mounted () {
    //document.getElementById('autosuggest__input').focus()
  },
  methods: {
    fetchResults(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {

        /*
        const photosPromise = axios.get(this.photosUrl);
        const usersPromise = axios.get(this.usersUrl);

        Promise.all([photosPromise, usersPromise]).then(values => {
          this.suggestions = [];
          this.selected = null;

          const photos = this.filterResults(values[0].data, val, "title");
          const users = this.filterResults(values[1].data, val, "name");

          users.length &&
            this.suggestions.push({ name: "destinations", data: users });
          photos.length &&
            this.suggestions.push({ name: "hotels", data: photos });
        });
        */
          this.suggestions = [];
          this.selected = null;

          const categories = this.filterResults(this.sections.categories.data, val, 'title');
          const reviews = this.filterResults(this.sections.reviews.data, val, 'title');
          const articles = this.filterResults(this.sections.articles.data, val, 'title');

          categories.length &&
            this.suggestions.push({ name: "categories", data: categories });
          reviews.length &&
            this.suggestions.push({ name: "reviews", data: reviews });
          articles.length &&
            this.suggestions.push({ name: "articles", data: articles });

      }, this.debounceMilliseconds);
    },
    filterResults(data, text, field) {
      return data
        .filter(item => {
          if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
            return item[field];
          }
        })
        .sort();
    },
    renderSuggestion(suggestion) {
      if (suggestion.name === 'categories') {
        return (
          <div>
            <img class={{ autosuggest__image: true, category: true }} src={suggestion.item.image} />
            {suggestion.item.title}
          </div>
        );
      } else if (suggestion.name === 'reviews') {
        return (
          <div>
            <img class={{ autosuggest__image: true, review: true }} src={suggestion.item.image} />
            {suggestion.item.title}
          </div>
        )
      } else {
        return suggestion.item.title;
      }
    },
    getSuggestionValue(suggestion) {
      let { name, item } = suggestion;
      return name == "hotels" ? item.title : item.title;
    }
  }
};
</script>

<style>
</style>
