import './scss/main.scss'


// fontawesome
/* eslint-disable no-unused-vars */
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faSearch, faCaretDown, faChevronRight, faPhone, faArrowCircleDown, faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
library.add(faSearch, faCaretDown, faChevronRight, faPhone, faArrowCircleDown, faArrowCircleUp);
dom.watch();
/* eslint-enable no-unused-vars */

// Vue stuff
import Vue from 'vue'
import SearchBarContainer from './components/search-bar/SearchBarContainer.vue'
import UserReviewContainer from './components/UserReviewContainer.vue'
import PopOnScrollContainer from './components/pop-on-scroll/PopOnScrollContainer.vue'
import ExitIntentContainer from './components/exit-intent/ExitIntentContainer.vue'
import store from './store'
import VeeValidate from 'vee-validate';
import { Validator } from 'vee-validate';
const dictionary = {
  en: {
    attributes: {
      first_name: 'first name',
      last_name: 'last name',
      email_address: 'email address',
      zip_code: 'zip code'
    },
    custom: {
      agreement: {
        required: 'You must accept the terms before continuing.'
      }
    }
  },
};

Validator.localize(dictionary);

import 'animate.css/animate.min.css'
import VueClipboard from 'vue-clipboard2'
import { farewellDirectivePlugin } from 'vue-farewell'
import AnalyticsHelper from './analytics.js'


window.toggleSearchForm = () => {
  document.getElementsByClassName('header-search-form')[0].classList.toggle('opened')
  document.getElementById('autosuggest__input').focus()
}

Vue.use(VeeValidate)
Vue.use(VueClipboard)
Vue.use(farewellDirectivePlugin, {
  aggressive: true
})
Vue.config.productionTip = false



if (document.getElementById('vue-user-review')) {
  new Vue({
    store,
    render: h => h(UserReviewContainer)
  }).$mount('#vue-user-review')
}


if (document.getElementById('vue-search')) {
  new Vue({
    store,
    render: h => h(SearchBarContainer)
  }).$mount('#vue-search')
}

if (document.getElementById('vue-pop-on-scroll')) {
  new Vue({
    store,
    render: h => h(PopOnScrollContainer)
  }).$mount('#vue-pop-on-scroll')
}

if (document.getElementById('vue-exit-intent')) {
  new Vue({
    store,
    render: h => h(ExitIntentContainer)
  }).$mount('#vue-exit-intent')
  /*
  setTimeout(() => {
    new Vue({
      store,
      render: h => h(ExitIntentContainer)
    }).$mount('#vue-exit-intent')
  }, 5000)
  */
}

// Handle analytics events
new AnalyticsHelper