<template>
  <div :class="$style.screen">
    <slot></slot>
  </div>
</template>
<script>
/*
<transition name="user-review-form-anim" enter-active-class="animated lightSpeedIn" leave-active-class="animated zoomOut">
      <div id="user-review-form">
        <LoaderIcon v-if="showSpinner" />
        <transition name="user-review-form-anim" enter-active-class="animated bounceIn" leave-active-class="animated zoomOut">
          <UserReviewSuccess v-if="showSuccess" :email="email" />
        </transition>
        <transition name="user-review-form-anim" enter-active-class="animated lightSpeedIn" leave-active-class="animated zoomOut">
          <UserReviewError v-if="showError" />
        </transition>
        <form @submit.prevent="submitForm">
          <div class="form-group" v-if="!loggedIn">
            <label>Your name (displayed publicly)</label>
            <input
              v-model="name"
              v-validate="'required'" 
              :class="{ 
                'form-control': true, 
                'is-invalid': errors.first('name') 
              }"
              name="name" 
              type="text"
              placeholder="e.g. Jane Doe" />
            <div 
              :class="{ 
                'invalid-feedback d-block': errors.first('name') 
              }" 
              v-html="errors.first('name')">
            </div>
          </div>
          <div class="form-group" v-if="!loggedIn">
            <label>Your email (kept private!)</label>
            <input
              v-model="email"
              v-validate="'required|email'" 
              :class="{ 
                'form-control': true, 
                'is-invalid': errors.first('enail') 
              }"
              name="email" 
              type="email"
              placeholder="e.g. janedoe@example.com" />
            <div 
              :class="{ 
                'invalid-feedback d-block': errors.first('email') 
              }" 
              v-html="errors.first('email')">
            </div>
          </div>
          <div class="form-group star-rating">
            <label v-html="starLabelText"></label>
            <div class="d-flex align-items-center">
              <span class="mr-3">Bad</span>
              <star-rating
                name="rating"
                v-model="reviewRating"
                v-validate="'required|decimal|min_value:0.5'"
                :increment="0.5"
                :show-rating="false"
                :star-size="25"
              ></star-rating>
              <span class="ml-3">Good</span>
            </div>
            <div 
              :class="{ 
                'invalid-feedback d-block': errors.first('rating') 
              }" 
              v-html="errors.first('rating')">
            </div>
          </div>
          <div class="form-group">
            <label>In one sentence</label>
            <input
              v-model="reviewTitle"
              v-validate="'required'" 
              :class="{ 
                'form-control': true, 
                'is-invalid': errors.first('title') 
              }"
              name="title" 
              type="text"
              placeholder="Give a short summary of your review." />
            <div 
              :class="{ 
                'invalid-feedback d-block': errors.first('title') 
              }" 
              v-html="errors.first('title')">
            </div>
          </div>
          <div class="form-group">
            <label v-html="whyStarLabelText"></label>
            <textarea
              v-model="reviewMessage"
              name="message"
              :class="{
                'form-control': true,
                'is-invalid d-block': errors.first('message') 
              }"
              placeholder="Give us your thoughts and feelings here."
              ></textarea>
            <div
              :class="{ 
                'invalid-feedback d-block': errors.first('message') 
              }" v-html="errors.first('message')"></div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input v-validate="'required'" name="terms" class="form-check-input" type="checkbox" :value="termsChecked" id="termsCheck1">
              <label class="form-check-label" for="termsCheck1">
                I read and agree to all <a href="/terms-of-use" target="_blank">terms of service</a> and the <a href="/privacy-policy" target="_blank">privacy policy</a>.
              </label>
            </div>
            <div
              :class="{ 
                'invalid-feedback d-block': errors.first('terms') 
              }" v-html="errors.first('terms')"></div>
          </div>
          <button type="submit" class="btn btn-primary btn-block">Send Review</button>
        </form>
      </div>
    </transition>
*/
export default {
  name: 'UserReviewForm',
  props: {
    submitHandler: Function,
    showSuccess: Boolean,
    showError: Boolean,
    showSpinner: Boolean
  },
  data () {
    return {
      termsChecked: false
    }
  },
  computed: {
    reviewName () {
      return this.$store.state.reviewName
    },
    starLabelText () {
      return `How would you rate ${this.reviewName}?`
    },
    whyStarLabelText() {
      return `Why did you give ${this.reviewName} a rating of ${this.reviewRating}?`
    },
    loggedIn () {
      return this.$store.state.loggedIn
    },
    reviewTitle: {
      get () {
        return this.$store.state.reviewTitle
      },
      set (newValue) {
        this.$store.dispatch('setReviewTitle', newValue)
      }
    },
    reviewRating: {
      get () {
        return this.$store.state.reviewRating
      },
      set (newValue) {
        this.$store.dispatch('setReviewRating', newValue)
      }
    },
    reviewMessage: {
      get () {
        return this.$store.state.reviewMessage
      },
      set (newValue) {
        this.$store.dispatch('setReviewMessage', newValue)
      }
    }
  },
  methods: {
    async submitForm () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submitHandler()
          return
        }
      })
    }
  }
}
</script>
<style module>
.screen {
  padding: 0 0 2rem;
}
</style>