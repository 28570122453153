import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    username: '',
    name: '',
    email: '',
    showSpinner: false,
    showSnackbar: false,
    showCompareModal: false,
    showSearchForm: false,
    itemsOnPage: {},
    itemsToCompare: {},
    reviewType: null,
    reviewName: null,
    reviewId: null,
    reviewImage: null,
    showUserReviewForm: true,
    showUserReviewSuccess: false,
    showUserReviewError: false,
    reviewTitle: '',
    reviewRating: 0,
    reviewMessage: '',
    showCollapsedContent: false,
  },
  getters: {
    itemsCount (state) {
      return Object.keys(state.itemsToCompare).length
    }
  },
  mutations: {
    toggleSpinner (state, isVisible) {
      state.showSpinner = isVisible
    },
    toggleSnackbar (state, isVisible) {
      state.showSnackbar = isVisible
    },
    toggleCompareModal(state, isVisible) {
      state.showCompareModal = isVisible
    },
    toggleSearchForm(state, isVisible) {
      state.showSearchForm = isVisible
    },
    addItem (state, id) {
      if (!state.itemsOnPage.hasOwnProperty(id)) return
      Vue.set(state.itemsToCompare, id, state.itemsOnPage[id])
    },
    removeItem (state, id) {
      Vue.delete(state.itemsToCompare, id)
    },
    clearAllItems (state) {
      Vue.set(state, 'itemsToCompare', {})
    },
    setItemsOnPage (state, items) {
      Vue.set(state, 'itemsOnPage', items)
    },
    setItemsToCompare(state, items) {
      Vue.set(state, 'itemsToCompare', items)
    },
    toggleUserReviewForm(state, isVisible) {
      state.showUserReviewForm = isVisible
    },
    toggleUserReviewSuccess(state, isVisible) {
      state.showUserReviewSuccess = isVisible
    },
    toggleUserReviewError(state, isVisible) {
      state.showUserReviewError = isVisible
    },
    setReviewTitle (state, title) {
      state.reviewTitle = title
    },
    setReviewRating (state, rating) {
      state.reviewRating = rating
    },
    setReviewMessage (state, message) {
      state.reviewMessage = message
    },
    setName (state, name) {
      state.name = name
    },
    setEmail (state, email) {
      state.email = email
    },
    setReviewId (state, id) {
      state.reviewId = id
    },
    setReviewName (state, name) {
      state.reviewName = name
    },
    setReviewImage (state, url) {
      state.reviewImage = url
    },
    setReviewType (state, type) {
      state.reviewType = type
    },
    toggleCollapsedContent (state, visibility) {
      state.showCollapsedContent = visibility
    },
    setPopOnScroll (state, data) {
      state.popOnScroll = data
    },
    setExitIntent (state, data) {
      Vue.set(state, 'exitIntent', data)
    },
    setAutosuggest(state, data) {
      Vue.set(state, 'autosuggest', data)
    }
  },
  actions: {
    toggleSpinner (context, isVisible) {
      context.commit('toggleSpinner', isVisible)
    },
    toggleSnackbar (context, isVisible) {
      context.commit('toggleSnackbar', isVisible)
    },
    toggleCompareModal (context, isVisible) {
      context.commit('toggleCompareModal', isVisible)
    },
    toggleSearchForm(context, isVisible) {
      context.commit('toggleSearchForm', isVisible)
    },
    addItem (context, item) {
      context.commit('addItem', item)
    },
    removeItem (context, id) {
      context.commit('removeItem', id)
      const checkbox = document.getElementById(`fr-compare-checkbox-${id}`)
      checkbox.checked = false
    },
    clearAllItems (context) {
      context.commit('clearAllItems')

      // uncheck checkboxes
      const checkboxes = Array.from(document.getElementsByClassName('fr-compare-checkbox'))
      checkboxes.map(checkbox => {
        checkbox.checked = false
      })

    },
    setItemsOnPage(context, items) {
      context.commit('setItemsOnPage', items)
    },
    setItemsToCompare(context, items) {
      context.commit('setItemsToCompare', items)
    },
    toggleUserReviewForm(context, isVisible) {
      context.commit('toggleUserReviewForm', isVisible)
    },
    toggleUserReviewSuccess(context, isVisible) {
      context.commit('toggleUserReviewSuccess', isVisible)
    },
    toggleUserReviewError(context, isVisible) {
      context.commit('toggleUserReviewError', isVisible)
    },
    setReviewTitle (context, title) {
      context.commit('setReviewTitle', title)
    },
    setReviewRating(context, rating) {
      context.commit('setReviewRating', rating)
    },
    setReviewMessage(context, message) {
      context.commit('setReviewMessage', message)
    },
    setName(context, name) {
      context.commit('setName', name)
    },
    setEmail(context, email) {
      context.commit('setEmail', email)
    },
    setReviewId(context, id) {
      context.commit('setReviewId', id)
    },
    setReviewName(context, name) {
      context.commit('setReviewName', name)
    },
    setReviewImage(context, url) {
      context.commit('setReviewImage', url)
    },
    setReviewType(context, type) {
      context.commit('setReviewType', type)
    },
    toggleCollapsedContent(context, visibility) {
      context.commit('toggleCollapsedContent', visibility)
    }
  }
})
