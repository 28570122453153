import { render, staticRenderFns } from "./NoticeText.vue?vue&type=template&id=07454b8a&"
import script from "./NoticeText.vue?vue&type=script&lang=js&"
export * from "./NoticeText.vue?vue&type=script&lang=js&"
import style0 from "./NoticeText.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

component.options.__file = "NoticeText.vue"
export default component.exports