<template>
    <div>
        <transition name="v-model-bg-anim" enter-active-class="animated fadeIn fast" leave-active-class="animated fadeOut fast">
            <div class="v-modal-bg" v-show="showModal"></div>
        </transition>
        <transition name="v-model-anim" enter-active-class="animated tada fast" leave-active-class="animated flipOutX fast">
            <div class="v-modal" v-farewell="onLeave" v-show="showModal">
                <div class="v-modal-wrapper">
                    <div class="v-modal-top-bar" v-if="modalData.topBarText">
                        <svg svg-inline="" focusable="false" role="presentation" width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.431 7.655a10.504 10.504 0 0 1 6.308-4.451.645.645 0 0 0 .397-.972A4.745 4.745 0 0 0 5.11 0 4.756 4.756 0 0 0 .354 4.755c0 1.114.385 2.138 1.028 2.948a.649.649 0 0 0 1.049-.047zM19.598 0c-1.69 0-3.172.884-4.014 2.215a.649.649 0 0 0 .398.975 10.497 10.497 0 0 1 6.336 4.418.646.646 0 0 0 1.052.037c.616-.8.984-1.8.984-2.89A4.757 4.757 0 0 0 19.598 0zM22.867 14.563c0-1.836-.477-3.561-1.31-5.062a10.496 10.496 0 0 0-7.576-5.28c-.52-.08-1.048-.134-1.59-.134-.566 0-1.118.058-1.66.144a10.5 10.5 0 0 0-7.533 5.313 10.409 10.409 0 0 0-1.284 5.019c0 3.113 1.373 5.905 3.535 7.825l-.882 1.243a1.19 1.19 0 0 0 1.938 1.378l.882-1.243a10.412 10.412 0 0 0 5.004 1.275c1.81 0 3.515-.463 5.002-1.275l.883 1.243a1.186 1.186 0 0 0 1.657.28 1.19 1.19 0 0 0 .282-1.658l-.883-1.243c2.162-1.92 3.535-4.712 3.535-7.825zM12.391 22.11c-4.161 0-7.546-3.385-7.546-7.547 0-1.297.329-2.518.907-3.586a7.56 7.56 0 0 1 5.476-3.862c.38-.06.767-.1 1.163-.1.374 0 .739.037 1.098.09a7.56 7.56 0 0 1 5.53 3.852c.586 1.072.919 2.3.919 3.605 0 4.163-3.385 7.548-7.547 7.548z" fill="#fff"/><path d="M16.619 16.328l-2.879-1.777a1.375 1.375 0 0 0-.498-.833l.132-3.612a.837.837 0 0 0-1.67-.062l-.133 3.617a1.39 1.39 0 0 0 1.408 2.385l2.762 1.706a.836.836 0 0 0 .878-1.424z" fill="#fff"/></svg>
                        <p v-html="modalData.topBarText"></p>
                    </div>
                    <div class="v-modal-main-wrapper">
                        <div class="v-modal-image">
                            <img :src="modalData.imageUrl" alt="" />
                        </div>
                        <div class="v-modal-content-wrapper">
                            <h1 class="title" v-html="modalData.headingText"></h1>
                            <div class="v-modal-content" v-html="modalData.content"></div>
                            <a :href="modalData.buttonUrl" class="button primary" v-html="modalData.buttonText" target="_blank"></a>
                            <p class="v-close-modal" @click="onClose" v-html="modalData.closeText"></p>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
  name: "VModal",
  props: {
    showModal: Boolean,
    onLeave: Function,
    onClose: Function,
    modalData: Object,
    headingText: String,
    content: String,
    buttonText: String,
    buttonUrl: String,
    closeText: String,
    imageUrl: String
  }
};
</script>

<style>
.v-modal-bg {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 99999;
    width: 100%;
}
.v-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-right: 10px;
    padding-left: 10px;
    overflow: hidden;
    z-index: 999999;
    width: 100%;
}
.v-modal-wrapper {
    background: #ffffff;
    border-radius: 15px;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 0 0 0;
    z-index: 99999;
}
v-modal-main-wrapper {

}

.v-modal-top-bar {
    background-color: #14C3A7;
    border-radius: 15px 15px 0 0;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 54px;
    padding: 1rem 1.6875rem;
}
.v-modal-top-bar p {
    color: #ffffff;
    font-size: 1.25rem;
    line-height: 1.50rem;
    margin: 0 0 0 0.5rem;
}
.v-modal-wrapper .title {
    font-style: normal;
    font-weight: bold;
    line-height: 1.5rem;
    font-size: 1.5rem;
    color: #172847;
}
.v-modal-wrapper .title span {
    color: #00C6A7;
}
.v-modal-content p {
    color: #6D748C;
}
.v-modal-content ul {
    list-style-type: none;
    margin: 1.5rem 0 1.5rem 0;
    padding: 0;
}
.v-modal-content ul li {
    font-weight: 500;
    padding-left: 1.5rem;
    margin-bottom: 10px;
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: #152749;
    position: relative;
}
.v-modal-content ul li:before {
    content: ' ';
    display: block;
    background-image: url('../../assets/images/checkmark--green.svg');
    background-repeat: no-repeat;
    width: 0.9375rem;
    height: 0.875rem;      
    display: block;
    position: absolute;
    top: 0.3125rem;
    left: 0;
}
.v-close-modal {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    line-height: 1rem;
    font-size: 0.8rem;
    color: #6d728e;
}
.v-close-modal:hover {
    text-decoration: underline;
}
.v-modal-image {
    display: none;
    height: 100%;
    text-align: center;
}
.v-modal-image img {
    height: 100%;
    object-fit: contain;
    width: 100%;
}
.v-modal-content-wrapper .button.primary {
    padding: 0 1rem;
    width: 100%;
}
.v-modal-wrapper .btn-info {
    background: #0051ff;
    border: 1px solid #0051ff;
    border-radius: 4px;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem !important;
    color: #FFFFFF;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.v-modal-wrapper .btn-info:hover {
    background: #2E97FF;
}
.v-modal-content-wrapper {
    padding: 1.6875rem 0 1rem 1.6875rem;
}
@media screen and (min-width: 576px) {

}
@media screen and (min-width: 768px) {
    .v-modal-main-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }
    .v-modal-image {
        display: block;
        padding: 0 1rem 0 3rem;
    }
}
@media screen and (min-width: 960px) {
    .v-modal-wrapper {
        display: flex;
        width: 814px;
    }
    .v-modal-wrapper .title {
        line-height: 3rem;
        font-size: 3rem;
        margin-bottom: 2rem;
    }
    .v-modal-content-wrapper {
        margin-right: 64px;
        flex: 1;
    }
    .v-modal-content ul li {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .v-modal-image {
        flex: 1;
    }
    .v-close-modal {
        line-height: 0.9rem;
        font-size: 0.9rem;
    }
    .v-modal-content ul li {
        background-position: top 11px left;
    }
}
</style>
