<template>
  <div :class="$style.wrapper">
    <FormErrorMessages :messages="errorMessages" />
    <input 
      :class="$style.input"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :value="computedValue"
      @input="onInput"
    />
  </div>
</template>
<script>
import FormErrorMessages from '@/components/user-review/FormErrorMessages.vue'

export default {
  name: 'FormInput',
  components: { FormErrorMessages },
  props: {
    placeholder: String,
    maxlength: Number,
    value: [String, Number],
    errorMessages: [Array, Object]
  },
  data () {
    return {
      newValue: this.value
    }
  },
  watch: {
    value (value) {
      this.newValue = value
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.newValue
      },
      set (value) {
        this.newValue = value
      }
    }
  },
  methods: {
    onInput(event) {
      this.$nextTick(() => {
          if (event.target) {
              this.computedValue = event.target.value
              this.$emit('input', event.target.value)
          }
      })
    }
  }
}
</script>
<style module>
.wrapper {
  margin-bottom: 1.5rem;
}
.input {
  border: 2.5px solid #2B2B2B;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  padding: 1rem;
  width: 100%;
}
.input:focus {

}
</style>