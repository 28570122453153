<template>
  <div :class="$style.wrapper">
    <FormErrorMessages :messages="errorMessages" />
    <textarea 
      :class="$style.textarea"
      :placeholder="placeholder"
      rows="3" 
      ref="textarea"
      :value="computedValue"
      @input="onInput" />
    <span :class="$style.mininum" v-if="minimum">Minimum {{ minimum }} characters. <strong>{{ remainingChars }} remaining.</strong></span>
  </div>
</template>
<script>
import FormErrorMessages from '@/components/user-review/FormErrorMessages.vue'

export default {
  name: 'FormTextarea',
  components: { FormErrorMessages },
  props: {
    errorMessages: [Array, Object],
    placeholder: String,
    minimum: Number,
    value: [Number, String]
  },
  data () {
    return {
      newValue: this.value
    }
  },
  watch: {
    value (value) {
      this.newValue = value
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.newValue
      },
      set (value) {
        this.newValue = value
        this.$emit('input', value)
      }
    },
    valueLength() {
      if (typeof this.computedValue === 'string') {
          return this.computedValue.length
      } else if (typeof this.computedValue === 'number') {
          return this.computedValue.toString().length
      }
      return 0
    },
    remainingChars () {
      if (!this.computedValue) return this.minimum
      return this.computedValue.length > this.minimum ? 0 : this.minimum - this.valueLength
    }
  },
  methods: {
    onInput(event) {
      this.textareaResize()
      this.$nextTick(() => {
          if (event.target) {
              this.computedValue = event.target.value
          }
      })
    },
    textareaResize () {
      this.$refs.textarea.style.minHeight = this.$refs.textarea.scrollHeight + 'px';
    }
  },
}
</script>
<style module>
.wrapper {
  margin-bottom: 1.5rem;
}
.textarea {
  border: 2.5px solid #2B2B2B;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;;
}
.textarea:focus {

}
.minimum {
  color: #384947;
  font-size: 0.875rem;
}
</style>