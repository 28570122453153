<template>
  <button
    :class="[$style.button, classObject]"
    @click="onClick"
  >
    <span v-if="!isLoading">
      {{ text }}<span v-if="!hideChevron" :class="$style.chevron">>></span>
    </span>
    <svg v-else="" svg-inline="" :class="$style.loading" focusable="false" role="presentation" width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff"><circle cx="15" cy="15" r="15"><animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/><animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/></circle><circle cx="60" cy="15" r="9" fill-opacity=".3"><animate attributeName="r" from="9" to="9" begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite"/><animate attributeName="fill-opacity" from=".5" to=".5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite"/></circle><circle cx="105" cy="15" r="15"><animate attributeName="r" from="15" to="15" begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite"/><animate attributeName="fill-opacity" from="1" to="1" begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite"/></circle></svg>
  </button>
</template>
<script>
export default {
  name: 'PrimaryButton',
  props: {
    text: String,
    isLoading: Boolean,
    isBlock: Boolean,
    hideChevron: Boolean,
    onClick: Function
  },
  data () {
      return {
        classObject: {
          [this.$style.block]: this.isBlock
        }
      }
  }
}
</script>
<style module>
.button {
  background: #00C3A7;
  border-radius: 0;
  border: 3px solid #2B2B2B;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  text-transform: uppercase;
}
.button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.block {
  width: 100%;
}
.chevron {
  margin-left: 0.8rem;
}
.loading {
  height: 0.85rem;
}
</style>