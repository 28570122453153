import axios from 'axios'

const api = {
  submitComment: async (data) => {
    return new Promise((resolve, reject) => {
      const results = axios.post(`/wp-json/findreviews/v1/user-reviews`, data)
      if (results) {
        resolve(results)
      } else {
        reject(results)
      }
    })
  }
}

export default api