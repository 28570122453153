<template>
  <div>
    <label :class="$style.label">{{ text }}</label>
    <p :class="$style.description" v-if="description">{{ description }}</p>
  </div>
</template>
<script>
export default {
  name: 'FormLabel',
  props: {
    text: String,
    description: String
  }
}
</script>
<style module>
.label {
  color: #384947;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 0.625rem;
}
.description {
  color: #384947;
  font-size: 1rem;
  margin-bottom: 0.625rem;
}
</style>