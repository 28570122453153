function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof (url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
    'send_to': 'AW-767667918/K1biCJjVvpMBEM7dhu4C',
    'event_callback': callback
  });
  return false;
}

function openPermalink(event, topicName, brand, srchPosition) {
  const eventCategory = 'click'
  const eventAction = `category-${topicName}`
  const eventLabel = brand

  if (typeof ga === 'function') {
    gtag('event', eventAction, {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'dimension1': srchPosition
    });
  }

  window.location.href = event.target.dataset.permalink
}

function openOutboundLink(event, topicName, brand, srchPosition) {
  const outboundLink = (event.target.dataset.outboundLink ? event.target.dataset.outboundLink : event.target.closest('a').dataset.outboundLink)

  // fire facebook pixel event
  if (typeof fbq === 'function' && brand === 'Identity Guard') {
      fbq('trackCustom', 'IdgClickout');
  }

  if (typeof gtag === 'function') {
    const eventCategory = 'clickout'
    const eventAction = `category-${topicName}`
    const eventLabel = brand

    gtag('event', eventAction, {
      'event_category': eventCategory,
      'event_label': eventLabel,
      'dimension1': srchPosition
    });

    gtag_report_conversion()
  }
  window.open(outboundLink)
}

class AnalyticsHelper {
  constructor () {
    if (!document.querySelector('.topic-name')) return
    this.topicName = document.querySelector('.topic-name').textContent
    this.addEventListeners()
  }
  topicPageClick (event) {
    event.preventDefault()
    event.stopPropagation()

    const brand = event.target.closest('.elem__website').dataset.brand
    const srchPosition = event.target.closest('.elem__website').dataset['srch-position']

    // check if data tag is for permalink
    if (event.target.dataset.permalink) {
      openPermalink(event, this.topicName, brand, srchPosition)
      return
    }

    // check if data tag is for outbound click
    if (event.target.dataset || event.target.closest('a').dataset) {
      if (event.target.dataset.outboundLink || event.target.closest('a').dataset.outboundLink) {
        openOutboundLink(event, this.topicName, brand, srchPosition)
        return
      }
    }


    if (event.target.href) {
      window.open(event.target.href)
    }
    return
    
  }
  addEventListeners () {
    document.querySelectorAll('.elem__website').forEach(item => {
      item.addEventListener('click', (event) => {        // check if it's a click from the More Info buttono
        if (event.target.classList.contains('description-toggle-label')) {
          // stop thirstyaffiliates from hijacking event
          event.stopPropagation()
          event.preventDefault()
          event.stopImmediatePropagation()
          const checked = document.querySelector('#' + event.target.htmlFor).checked
          document.querySelector('#' + event.target.htmlFor).checked = !checked
          return
        } 
        // check for topic page elements
        this.topicPageClick(event)
      }, false)
    })


    /*
    // Topic Page - Link Clicks
    const categoryItems = document.querySelectorAll('.elem__website')
    if (categoryItems) {
      categoryItems.forEach(item => {
        item.addEventListener('click', this.topicPageClick.bind(this), false)
        item.addEventListener('auxclick', this.topicPageClick.bind(this), false)
      })
    }
    */

  }

}

export default AnalyticsHelper