<template>
  <div :class="$style.screen">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'SuccessScreen'
}
</script>
<style module>
.screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.screen > p {
  color: #384947;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.screen svg {
  width: 75%;
  margin-bottom: 2rem;
}
</style>