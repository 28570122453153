<template>
  <span :class="$style.errors" v-if="messages">
    <p v-for="message in messages" :key="message">{{ message }}</p>
  </span>
</template>
<script>
export default {
  name: 'FormErrorMessage',
  props: {
    messages: [Array, Object]
  }
}
</script>
<style module>
.errors {
  color: rgb(255, 0, 0);
}
.errors p {
  color: rgb(255, 0, 0);
}
</style>