<template>
    <PopOnScroll 
        :show-pop="showPop" 
        :logo-label="logoLabel"
        :offer-label="offerLabel"
        :offer-amount="offerAmount"
        :offer-main-text="offerMainText"
        :cta-text="ctaText"
        :cta-url="ctaUrl"
    />
</template>
<script>
import PopOnScroll from './PopOnScroll.vue';
import { isUndefined } from 'lodash'

export default {
    name: 'PopOnScrollContainer',
    components: { PopOnScroll },
    data () {
        return {
            showPop: false
        }
    },
    computed: {
        logoLabel () {
            return this.$store.state.popOnScroll.logoLabel
        },
        offerLabel () {
            return this.$store.state.popOnScroll.offerLabel
        },
        offerAmount () {
            return this.$store.state.popOnScroll.offerAmount
        },
        offerMainText () {
            return this.$store.state.popOnScroll.offerMainText
        },
        ctaText () {
            return this.$store.state.popOnScroll.ctaText
        },
        ctaUrl () {
            return this.$store.state.popOnScroll.ctaUrl
        },
    },
    methods: {
        scrollHandler() {
            const pixelsToScroll = 400
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
            if (scrollTop && scrollTop > pixelsToScroll) {
                this.showPop = true
                return
            }
            this.showPop = false
        }
    },
    created () {
        // Set initial state
        if (!isUndefined(window.__initialState__) && !isUndefined(window.__initialState__.popOnScroll)) {
            this.$store.commit('setPopOnScroll', window.__initialState__.popOnScroll)
            window.addEventListener('scroll', this.scrollHandler)
        }
    },
    destroyed () {
        window.removeEventListener('scroll', this.scrollHandler)
    }
};
</script>