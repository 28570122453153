<template>
  <div :class="$style.notice">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'NoticeText'
}
</script>
<style module>
.notice {
  color: #384947;
  font-size: 0.875rem;
  margin: 1rem 0;
}
</style>