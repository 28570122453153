<template>
  <div :class="$style.wrapper">
    <FormErrorMessages :messages="errorMessages" />
    <label :class="$style.label">
      <input
        type="checkbox"
        class="checkbox"
        :checked="value"
        @change="$emit('input', $event.target.checked)"
         />
      <slot></slot>
    </label>
  </div>
</template>
<script>
import FormErrorMessages from '@/components/user-review/FormErrorMessages.vue'

export default {
  name: 'Formcheckbox',
  components: { FormErrorMessages },
  props: {
    errorMessages: [Array, Object],
    text: String,
    value: Boolean
  }
}
</script>
<style module>
.wrapper {
  margin: 1rem 0;
}
.label {
  display: block;
  padding-left: 20px;
  text-indent: -20px;
  font-size: 0.85rem;
}
</style>