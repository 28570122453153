<template>
    <transition
        name="poponscroll-anim"
        enter-active-class="animated slideInUp fast"
        leave-active-class="animated slideOutDown fast">
        <div id="pop-on-scroll" v-show="showPop">
            <div class="pop-on-scroll-wrapper">
                <div class="shadow-arrow">
                    <div class="logo"></div>
                    <h4 class="logo-label" v-html="logoLabel"></h4>
                </div>
                <div class="offer-tag">
                    <h6 class="offer-label" v-html="offerLabel"></h6>
                    <h4 class="offer-amount" v-html="offerAmount"></h4>
                </div>
                <h4 class="offer-main-text" v-html="offerMainText"></h4>
                <a :href="ctaUrl" class="button primary" v-html="ctaText" target="_blank" v-if="ctaText"></a>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'PopOnScroll',
    props: {
        showPop: Boolean,
        logoLabel: String,
        offerLabel: String,
        offerAmount: String,
        offerMainText: String,
        ctaText: String,
        ctaUrl: String,
    }
}
</script>

<style scoped>
#pop-on-scroll {
    background-color: #ffffff;
    box-shadow: 0px -4px 11px rgba(0, 0, 0, 0.142408);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 81px;
    position: fixed;
    padding: 0 0.25rem;
    top: auto;
    bottom: 0;
    right: 0;
    left: 0;
    z-index:99999999;
}
.pop-on-scroll-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.shadow-arrow {
    background-image: url('../../assets/images/shadow-arrow.svg');
    background-position: 0 -4px;
    display: none;
    align-items: center;
    justify-content: space-around;
    min-width: 335px;
    height: 100%;
    padding-right: 48px;
}
.logo-label {
    color: #9A9AA7;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-size: 17px;
    margin: 10px 0 0;
}
.offer-tag {
    margin-top: 10px;

}
.offer-tag .offer-label {
    font-style: normal;
    font-weight: bold;
    line-height: 18px;
    font-size: 0.7rem;
    letter-spacing: 0.9px;
    color: #172847;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    margin-top: -12px;
    margin-bottom: 0;
}
.offer-tag .offer-amount {
    font-family: 'FSAlbert', sans-serif;;
    font-style: normal;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1rem;
    color: #00C4A7;
    text-align: center;
    margin: 0;
}
.offer-main-text {
    font-size: 0.9rem;
    margin: 0 1rem 0;
    text-align: center;
}
#pop-on-scroll .button {
    font-size: 0.9rem !important;
    padding-right: 0.4rem !important;
    padding-left: 0.4rem !important;
    min-width: 100px;
}
@media screen and (min-width: 577px) {
    #pop-on-scroll {
    }
}
@media screen and (min-width: 768px) {
    .shadow-arrow {
        display: flex;
    }
}
@media screen and (min-width: 968px) {
    .offer-tag .offer-label {
        font-size: 0.9rem;
    }
    .offer-tag .offer-amount {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    .offer-main-text {
        font-size: 1.5rem;
        text-align: left;
    }
    #pop-on-scroll .button {
        padding: 0.375rem 1rem !important;
        font-size: 1rem !important;
    }
}
</style>
