<template>
    <VModal
        :on-leave="leaveHandler"
        :on-close="closeHandler"
        :show-modal="showModal"
        :modal-data="exitIntentData"
    />
</template>

<script>
import VModal from './VModal.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { isUndefined } from 'lodash'

export default {
    name: 'ExitIntentContainer',
    components: { VModal },
    created () {
        // Set initial state
        if (!isUndefined(window.__initialState__) && !isUndefined(window.__initialState__.exitIntent)) {
            this.$store.commit('setExitIntent', window.__initialState__.exitIntent)
        }
    },
    data () {
        return {
            showModal: false
        }
    },
    computed: {
        exitIntentData () {
            return this.$store.state.exitIntent
        }
    },
    methods: {
        leaveHandler () {
            disableBodyScroll(this.modalTarget)
            this.showModal = true
        },
        closeHandler () {
            enableBodyScroll(this.modalTarget)
            this.showModal = false
        }
    }
}
</script>

<style>

</style>
